'use client';
import { toastError } from '@/types/general';
import { log } from '@/utils/logger';
import { removeFromURL } from '@/utils/random';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { toast } from 'sonner';

/**
 * This component will render a toast whenever the URL contains an error or success message.
 * It will also remove the error or success message from the URL after rendering the toast.
 */
export default function URLToaster() {
    const searchParams = useSearchParams();
    const error = searchParams.get('error');
    const success = searchParams.get('success');
    const message = searchParams.get('message');
    const warning = searchParams.get('warning');
    const router = useRouter();
    const pathname = usePathname();

    useEffect(() => {
        if (error) {
            log({
                message: 'Error in URLToaster',
                level: 'info',
                error,
            });

            toastError(error);
            // Clear the error from the URL
            router.replace(removeFromURL('error', pathname));
        } else if (success) {
            toast.success(success, {
                id: btoa(success),
            });
            // Clear the success from the URL
            router.replace(removeFromURL('success', pathname));
        } else if (message) {
            toast.info(message, {
                id: btoa(message),
            });
            router.replace(removeFromURL('success', pathname));
        } else if (warning) {
            toast.warning(warning, {
                id: btoa(warning),
            });
            router.replace(removeFromURL('warning', pathname));
        }
    }, [error, success, message, pathname, router, warning]);

    return null;
}
