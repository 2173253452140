'use client';

import { getClientComponentClient } from '@/utils/clientSupaUtils';
import { log } from '@/utils/logger';
import { getProfileInfo, getSupabaseUserIdSafe } from '@/utils/supaUtils';
import { memo, useEffect } from 'react';
import { setUser } from '@sentry/nextjs';

interface Props {
    company: string | undefined;
}
export default memo(function SentryUser({ company }: Props) {
    const supabase = getClientComponentClient();

    useEffect(() => {
        const registerSentryUser = async () => {
            const uid = await getSupabaseUserIdSafe(supabase);
            if (!uid) {
                setUser(null);
                return;
            }

            const profile = await getProfileInfo(supabase, uid, ['email', 'full_name']);

            setUser({
                id: uid,
                email: profile?.email,
                company,
                fullName: profile?.full_name,
            });
        };

        registerSentryUser().catch((e) => {
            log({
                message: 'Error registering Sentry user',
                error: e,
                level: 'error',
            });
        });
    }, [supabase, company]);

    return <> </>;
});
