'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink, getFetch, loggerLink, splitLink } from '@trpc/client';
import { useState } from 'react';
import { trpc } from './client';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getEnvironmentVariable } from '../envVars';
import SuperJSON from 'superjson';
const edgeUrl = `${getEnvironmentVariable(process.env.NEXT_PUBLIC_BASE_URL)}/api/v1/trpc/`;
const nodeUrl = `${getEnvironmentVariable(process.env.NEXT_PUBLIC_BASE_URL)}/api/v1/trpcNode/`;

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: { staleTime: 5 * 1000, gcTime: Infinity },
                },
            })
    );

    const [trpcClient] = useState(() =>
        trpc.createClient({
            links: [
                loggerLink({
                    enabled: () =>
                        process.env.NODE_ENV === 'development' && typeof window !== 'undefined',
                }),
                splitLink({
                    condition(op) {
                        return op.context.environment === 'node';
                    },
                    true: httpLink({
                        url: nodeUrl,
                        fetch: async (input, init?) => {
                            // console.log('FETCH', Object.keys(init?.headers));
                            const fetch = getFetch();
                            return fetch(input, {
                                ...init,
                                credentials: 'include',
                            });
                        },

                        transformer: SuperJSON,
                    }),
                    false: httpLink({
                        url: edgeUrl,
                        fetch: async (input, init?) => {
                            // console.log('FETCH', Object.keys(init?.headers));
                            const fetch = getFetch();
                            return fetch(input, {
                                ...init,
                                credentials: 'include',
                            });
                        },

                        transformer: SuperJSON,
                    }),
                }),
            ],
        })
    );

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                {children}
                {/* <ReactQueryDevtools /> */}
            </QueryClientProvider>
        </trpc.Provider>
    );
};
