'use client';
import { getEnvironmentVariable } from '@/utils/envVars';
import { usePathname, useSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

// https://posthog.com/docs/libraries/next-js
const key = getEnvironmentVariable(process.env.NEXT_PUBLIC_POSTHOG_KEY);

if (typeof window !== 'undefined') {
    posthog.init(key, {
        api_host: `${getEnvironmentVariable(process.env.NEXT_PUBLIC_BASE_URL)}/ingest`,
        mask_all_text: false,
        mask_all_element_attributes: false,
        ip: true,
        session_recording: {
            recordCrossOriginIframes: true,
            maskAllInputs: false,
            maskInputOptions: {
                password: true, // Highly recommended as a minimum!!
                color: false,
                date: false,
                'datetime-local': false,
                email: false,
                month: false,
                number: false,
                range: false,
                search: false,
                tel: false,
                text: false,
                time: false,
                url: false,
                week: false,
                textarea: false,
                select: false,
            },
        },
        capture_pageview: false,
        disable_session_recording: false,
        ui_host: 'https://app.posthog.com',
    });
}

export function PostHogPageview(): JSX.Element {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    useEffect(() => {
        if (pathname) {
            let url = window.origin + pathname;
            if (searchParams && searchParams.toString()) {
                url = url + `?${searchParams.toString()}`;
            }

            if (pathname === '/') {
                return;
            }

            if (pathname.startsWith('/auth')) {
                return;
            }

            posthog.capture('$pageview', {
                $current_url: url,
            });
        }
    }, [pathname, searchParams]);

    return <></>;
}

export function PHProvider({ children }: { children: React.ReactNode }) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
