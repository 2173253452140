import {
    type Company,
    type CompanyMemberRelationship,
    type Ghost,
    type Integration,
    type Profile,
    type Run,
    type Subscription,
    UserRoles,
    type RunWithFindCount,
    type DataTableUrlControls,
    type ParsedTableDataControls,
    type WithCount,
    type Contact,
    type OrgMember,
    type Find,
    type PlatformAlert,
    type Subset,
    type FindAction,
    type CountByDay,
    type PlanType,
    type RateLimitedFindAction,
    type ExternalContactRecord,
    type Invite,
    RunStatus,
} from '@/types/general';
import { type Database } from '@/types/supabase';
import { SupabaseClient } from '@supabase/supabase-js';
import { log } from './logger';
import { shuffleArray } from './random';
import type { ContactWithExternalContacts } from './contactHelpers';
import ky from 'ky';
import { getEnvironmentVariable } from './envVars';

/**
 * This type is a Supabase client that has been authenticated with a user.
 */
export type SupaAuthedClient = SupabaseClient<Database> & {
    clientType: 'Authenticated';
};

/**
 * This type is a Supabase client that has been authenticated with a service key.
 */
export type SupaServiceClient = SupabaseClient<Database> & { clientType: 'Service' };

/**
 * A supabase client that can be both the admin unrestricted client or an authed client.
 */
export type AnySupabaseClient = SupaAuthedClient | SupaServiceClient;

/**
 * Simple helper function to pull the Supabase user ID from the session.
 * This function will not throw an error if the user ID could not be found.
 * @param authedClient A Supabase client that has been authenticated.
 * @returns The Supabase user ID.
 * @throws If the Supabase user ID could not be found.
 */
export async function getSupabaseUserIdSafe(client: SupaAuthedClient): Promise<string | null> {
    const { data, error } = await client.auth.getSession();
    if (data) {
        const { session } = data;
        if (session) {
            return session.user.id;
        }
    }

    if (error) {
        log({
            level: 'warning',
            message: 'Could not get Supabase user ID',
            error,
        });
    }

    return null;
}

export async function getProfileInfo(
    client: AnySupabaseClient,
    id: string
): Promise<Profile | null>;

export async function getProfileInfo<T extends keyof Profile>(
    client: AnySupabaseClient,
    id: string,
    columns: T[]
): Promise<Subset<Profile, T> | null>;

/**
 * Returns all of the info for a user's profile.
 *
 * @param client Any supabase client. Profile is mentioned by ID so service is safe
 * @param id The Supabase user ID.
 * @param columns The columns to return, if not provided all columns will be returned.
 * @returns The user's profile info or null if it could not be found.
 */
export async function getProfileInfo<T extends keyof Profile>(
    client: AnySupabaseClient,
    id: string,
    columns?: T[]
): Promise<Profile | Subset<Profile, T> | null> {
    const selectCols = columns ? Array.from(new Set(columns)).join(',') : '*';

    const { data, error } = await client
        .from('profiles')
        .select(selectCols)
        .eq('id', id)
        .limit(1)
        .returns<Profile[] | Subset<Profile, T>[] | null>()
        .maybeSingle();

    if (error) {
        log({
            level: 'warning',
            message: `Could not load profile record`,
            error,
            extra: {
                uid: id,
            },
        });
        return null;
    }

    return data;
}

export async function getCompanyInfo(
    client: AnySupabaseClient,
    id: string
): Promise<Company | null>;

export async function getCompanyInfo<T extends keyof Company>(
    client: AnySupabaseClient,
    id: string,
    columns: T[]
): Promise<Subset<Company, T> | null>;

/**
 * Returns all of the info for a company's record.
 *
 * @param client Any supabase client. Company is mentioned by ID so service client is safe
 * @param id The Supabase company ID.
 * @param columns The columns to return, if not provided all columns will be returned.
 * @returns The company info or null if the company could not be found.
 */
export async function getCompanyInfo<T extends keyof Company>(
    client: AnySupabaseClient,
    id: string,
    columns?: T[]
): Promise<Company | Subset<Company, T> | null> {
    const selectCols = columns ? Array.from(new Set(columns)).join(',') : '*';

    const { data, error } = await client
        .from('companies')
        .select(selectCols)
        .eq('id', id)
        .limit(1)
        .returns<Company[] | Subset<Company, T>[] | null>();

    if (error || !data || !data[0]) {
        log({
            level: 'warning',
            message: `Could not load company record`,
            error,
            extra: {
                company_id: id,
            },
        });
        return null;
    }

    return data[0];
}

/**
 * Get the relationships between a user and the companies they are a member of.
 * @param client - A Supabase client that has been authenticated.
 * @param user_id - The Supabase user ID.
 * @returns - An array of relationships between the user and the companies they are a member of.
 */
export async function getUserCompanyRelationships(
    client: SupaAuthedClient,
    user_id: string
): Promise<CompanyMemberRelationship[]> {
    const { data, error } = await client
        .from('company_member_relationships')
        .select('*')
        .eq('user_id', user_id);

    if (error) {
        log({
            level: 'error',
            message: `Error getting company member relationships for user`,
            error,
            uid: user_id,
        });

        return [];
    }

    return data ?? [];
}

/**
 * Get's the role of a user in a company. Will return null if the user is not a member of the company.
 * @param client - A Supabase client that has been authenticated or a Supabase service client if you want to look up
 * the role of a user on their behalf.
 * @param user_id - The Supabase user ID.
 * @param company_id - The Supabase company ID.
 * @returns - The role of the user in the company or null if the user is not a member of the company (or error).
 */
export async function getUserRoleInCompany(
    client: AnySupabaseClient,
    user_id: string,
    company_id: string
): Promise<UserRoles | null> {
    const user = await client.auth.getUser();

    // UPDATE auth.users SET raw_app_meta_data = raw_app_meta_data || '{"role": "super-admin"}' WHERE id='<user_id>';
    if (user.data.user?.app_metadata.role === 'super-admin') {
        return UserRoles.owner;
    }

    const { data, error } = await client
        .from('company_member_relationships')
        .select('role')
        .eq('user_id', user_id)
        .eq('company_id', company_id)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting company member relationships for user`,
            error,
            uid: user_id,
            company_id,
        });
        return null;
    }

    if (!data) {
        return null;
    }

    return data.role as UserRoles;
}

/**
 * This function will delete an invite. It does this by deleting a company <> email invite pair
 * @param client - A Supabase client SERVICE Client
 * @param company_id - The Supabase company ID.
 * @param email - The email of the user to delete the invite for.
 * @returns Nothing
 */
export async function deleteInvite(
    client: SupaServiceClient,
    company_id: string,
    email: string
): Promise<void> {
    try {
        await client
            .from('invites')
            .delete()
            .eq('company_id', company_id)
            .eq('invited_email', email)
            .maybeSingle()
            .throwOnError();

        return;
    } catch (error) {
        log({
            level: 'error',
            message: `Error deleting invite`,
            error,
            extra: {
                email,
            },
            company_id,
        });
    }
}

export async function getCompanyMostRecentSubscription(
    client: AnySupabaseClient,
    company_id: string
): Promise<Subscription | null>;

export async function getCompanyMostRecentSubscription<T extends keyof Subscription>(
    client: AnySupabaseClient,
    company_id: string,
    columns: T[]
): Promise<Subset<Subscription, T> | null>;

/**
 * Get a company's subscription by
 * @param client A Supabase client that has been authenticated.
 * @param company_id The Supabase company ID.
 * @returns The subscription or null if it could not be found.
 */
export async function getCompanyMostRecentSubscription<T extends keyof Subscription>(
    client: AnySupabaseClient,
    company_id: string,
    columns?: T[]
): Promise<Subscription | Subset<Subscription, T> | null> {
    const selectCols = columns ? Array.from(new Set(columns)).join(',') : '*';

    const { data, error } = await client
        .from('subscriptions')
        .select(selectCols)
        .eq('company_id', company_id)
        .order('id', { ascending: false })
        .limit(1)
        .returns<Subscription[] | Subset<Subscription, T>[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting the most recent subscription for company`,
            error,
            company_id,
        });
        return null;
    }

    if (!data || !data[0]) {
        return null;
    }

    return data[0];
}

/**
 * This function will return all integrations (enabled and disabled) for a company.
 * @param client A Supabase client that has been authenticated.
 * @param company_id The Supabase company ID.
 * @returns The integrations for the company.
 */
export async function getCompanyIntegrations(
    client: AnySupabaseClient,
    company_id: string
): Promise<Integration[]> {
    const { data, error } = await client
        .from('integrations')
        .select('*')
        .eq('company_id', company_id);

    if (error) {
        log({
            level: 'error',
            message: `Error getting company integrations for company`,
            error,
            company_id,
        });
        return [];
    }

    return data ?? [];
}

/**
 * This function will return an integration record (enabled and disabled) for a company.
 * @param client A Supabase client that has been authenticated.
 * @param company_id The Supabase company ID.
 * @returns The integration for the company.
 */
export async function getCompanyIntegration(
    client: AnySupabaseClient,
    company_id: string,
    integration_service: Integration['service']
): Promise<Integration | null> {
    const { data, error } = await client
        .from('integrations')
        .select('*')
        .eq('company_id', company_id)
        .eq('service', integration_service)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting integration record for company`,
            error,
            company_id,
        });
        return null;
    }

    return data;
}

/**
 * This function will return a boolean indicating if a company has a given integration enabled.
 * This function is safe to use client side as it does not expose any sensitive information.
 * @param client The Supabase client to use.
 * @param company_id The Supabase company ID.
 * @param integration The integration to check.
 * @returns A boolean indicating if the integration is enabled.
 */
export async function isIntegrationEnabled(
    client: AnySupabaseClient,
    company_id: string,
    integration: Integration['service']
): Promise<boolean> {
    const { data, error } = await client
        .from('integrations')
        .select('service, enabled')
        .eq('company_id', company_id)
        .eq('service', integration)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting company integration`,
            error,
            company_id,
        });
        return false;
    }

    if (!data) {
        return false;
    }

    return data.enabled;
}

/**
 * This function will return all of the ghosts for a company.
 * @param client An authenticated Supabase client or a Supabase service client.
 * @param company_id The Supabase company ID.
 * @returns An array of ghosts for the company.
 */
export async function getCompanyGhosts(
    client: SupaAuthedClient | SupaServiceClient,
    company_id: string
): Promise<Ghost[]> {
    const { data, error } = await client
        .from('ghosts')
        .select('*')
        .eq('company_id', company_id)
        .order('created_at', { ascending: true })
        .returns<Ghost[]>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting company ghosts`,
            error,
            company_id,
        });
        return [];
    }

    return data ?? [];
}

/**
 * This function will return all of the ghosts for a company.
 *
 * **NOTE: You cannot use a ! check on this function. To check if null you must use `=== null`**
 *
 * @param client An authenticated Supabase client or a Supabase service client.
 * @param company_id The Supabase company ID.
 * @returns An array of ghosts for the company.
 */
export async function getCompanyGhostsCount(
    client: AnySupabaseClient,
    company_id: string
): Promise<number | null> {
    const { count, error } = await client
        .from('ghosts')
        .select('*', { count: 'exact', head: true })
        .eq('company_id', company_id);

    if (error) {
        log({
            level: 'error',
            message: `Error getting company ghost count`,
            error,
            company_id,
        });
        return null;
    }

    return count;
}

/**
 * This function will return the plan type of a company based on its primary subscription's product id.
 * @param client A Supabase client that has been authenticated.
 * @param company_id The Supabase company ID.
 * @returns The plan type of the company.
 */
export async function getPlanType(
    client: AnySupabaseClient,
    company_id: string
): Promise<PlanType> {
    const subscription = await getCompanyMostRecentSubscription(client, company_id, ['plan']);

    if (!subscription) {
        throw new Error(`Subscription not found for company ${company_id}`);
    }

    return subscription.plan;
}

/**
 * This function will return a given Ghost by its ID.
 * @param client The supabase client to use. If you use a SupaServiceClient, the ghost will be returned regardless of
 * of if it belongs to the company.
 * @param ghost_id The ID of the ghost to get.
 * @returns A ghost or null if it could not be found.
 */
export async function getGhost(
    client: SupaAuthedClient | SupaServiceClient,
    ghost_id: string
): Promise<Ghost | null> {
    const { data, error } = await client
        .from('ghosts')
        .select('*')
        .eq('id', ghost_id)
        .limit(1)
        .returns<Ghost[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting ghost`,
            error,
            extra: {
                ghost_id,
            },
        });
        return null;
    }

    return data && data[0] ? data[0] : null;
}

/**
 * This function will return a given run by its ID.
 * @param client The supabase client to use. If you use a SupaServiceClient, the run will be returned regardless of
 * of if it belongs to the company.
 * @param run_id The ID of the run to get.
 * @returns A run or null if it could not be found.
 */
export async function getRun(
    client: SupaAuthedClient | SupaServiceClient,
    run_id: string | number
): Promise<Run | null> {
    const { data, error } = await client
        .from('runs')
        .select('*')
        .eq('id', run_id)
        .limit(1)
        .returns<Run[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting run`,
            error,
            extra: {
                run_id,
            },
        });
        return null;
    }

    return data && data[0] ? data[0] : null;
}

/**
 * This function will get the most recent runs for a ghost.
 *
 * @param client The supabase client to use. If you use a SupaServiceClient, the run will be returned regardless of
 * of if it belongs to the company.
 * @param ghost_id The ID of the ghost to get runs for.
 * @param limit The number of runs to return. Defaults to 1.
 * @returns An array of runs or null if it could not be found.
 */
export async function getGhostRuns(
    client: SupaAuthedClient | SupaServiceClient,
    ghost_id: string,
    limit?: number
): Promise<Run[] | null> {
    const { data, error } = await client
        .from('runs')
        .select('*')
        .eq('ghost_id', ghost_id)
        .order('id', { ascending: false })
        .limit(limit ?? 1)
        .returns<Run[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting runs for ghost`,
            error,
            extra: {
                ghost_id,
            },
        });
        return null;
    }

    return data ?? null;
}

/**
 * This function will get the most recent _successful_ run for a ghost.
 * @param client The supabase client to use. If you use a SupaServiceClient, the run will be returned regardless of
 * of if it belongs to the company.
 * @param ghost_id The ID of the ghost to get runs for.
 * @returns The most recent successful run or null if it could not be found.
 */
export async function getLastSuccessfulGhostRun(
    client: SupaAuthedClient | SupaServiceClient,
    ghost_id: string
): Promise<Run | null> {
    const { data, error } = await client
        .from('runs')
        .select('*')
        .eq('ghost_id', ghost_id)
        .eq('status', RunStatus.succeeded)
        .order('id', { ascending: false })
        .limit(1)
        .returns<Run[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting last successful ghost run`,
            error,
            extra: {
                ghost_id,
            },
        });
        return null;
    }

    return data && data[0] ? data[0] : null;
}

/**
 * This function will "parse" the urlControls for a table and return the values
 * that can be used in db queries and such.
 * @param defaultSortKey  The default sort key to use if one is not provided
 * @param controls The urlControls for the request
 * @returns The parsed table data controls
 */
export const parseTableDataControls = (
    defaultSortKey: string,
    controls?: DataTableUrlControls
): ParsedTableDataControls => {
    const parsedPageSize = controls?.pageSize ?? '10';
    const parsedPage = controls?.page ?? '0';
    const parsedSortKey = controls?.sortKey ?? defaultSortKey;
    const parsedSortDirection = controls?.sortDirection === 'asc' ? true : false;
    const parsedHideKeys = controls?.hiddenKeys ? controls.hiddenKeys.split(',') : [];

    return {
        pageSize: parseInt(parsedPageSize),
        page: parseInt(parsedPage),
        sortKey: parsedSortKey,
        hiddenKeys: parsedHideKeys,
        sortDirection: parsedSortDirection,
        search: controls?.search,
    };
};

/**
 * This function will get the most recent runs for a ghost.
 *
 * @param client The supabase client to use. If you use a SupaServiceClient, the run will be returned regardless of
 * of if it belongs to the company.
 * @param ghost_id The ID of the ghost to get runs for.
 * @param limit The number of runs to return. Defaults to 1.
 * @param urlControls The urlControls for the request
 * @returns An array of runs or null if it could not be found.
 */
export async function getGhostRunsWithFindCount(
    client: SupaAuthedClient | SupaServiceClient,
    ghost_id: string,
    limit?: number,
    urlControls?: DataTableUrlControls
): Promise<WithCount<RunWithFindCount[]> | null> {
    const { pageSize, page, sortKey, sortDirection } = parseTableDataControls('id', urlControls);

    let query = client
        .from('runs')
        .select('*, finds(count)', { count: 'exact' })
        .eq('ghost_id', ghost_id);

    if (urlControls === undefined) {
        query = query.limit(limit ?? 1);
    } else {
        query = query.range(page * pageSize, (page + 1) * pageSize - 1);
    }

    const { data, count, error } = await query
        .order(sortKey, { ascending: sortDirection })
        .range(page * pageSize, (page + 1) * pageSize - 1)
        .returns<RunWithFindCount[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting runs with find count for ghost`,
            error,
            extra: {
                ghost_id,
            },
        });
        return null;
    }

    return {
        data: data ?? [],
        count: count ?? 0,
    };
}

/**
 * This function will get a Contact by their Contact ID
 * @param client A Supabase Client. If a service client is used the person will be returned regardless
 * of if the Contact belongs to the company
 * @param contactId The id of the contact
 * @returns The Contact object or null if nothing
 */
export async function getContact(
    client: SupaAuthedClient | SupaServiceClient,
    contactId: string
): Promise<Contact | null> {
    const { data, error } = await client
        .from('contacts')
        .select('*')
        .eq('id', contactId)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting contact`,
            error,
            extra: {
                contact_id: contactId,
            },
        });
        return null;
    }

    return data;
}

/**
 * This function will get the Contacts from a company either based
 * on passed in DataTableUrlControls or based on a limit
 * @param client The client to use. If a service one is provided the results will be returned regardless
 * of permission
 * @param company_id The company to grab the contacts from
 * @param limit The limit of the number of contacts to return. Will be ignored if urlControls is passed
 * @param urlControls The urlControls for the request
 * @returns An array of contacts with row count or null if nothing
 */
export async function getCompanyContactsWithCount(
    client: SupaAuthedClient | SupaServiceClient,
    company_id: string,
    limit?: number,
    urlControls?: DataTableUrlControls
): Promise<WithCount<ContactWithExternalContacts[]> | null> {
    const { pageSize, page, sortKey, sortDirection, search } = parseTableDataControls(
        'created_at',
        urlControls
    );

    let query = client
        .from('contacts')
        .select('*, external_contacts(*)', { count: 'exact' })
        .eq('company_id', company_id);

    if (urlControls === undefined) {
        query = query.limit(limit ?? 1);
    } else {
        query = query.range(page * pageSize, (page + 1) * pageSize - 1);
    }

    if (search && search !== '') {
        query = query.ilike('display_name', `%${search}%`);
    }

    if (sortKey === 'name') {
        query = query.order('display_name', { ascending: sortDirection });
    } else {
        query = query.order(sortKey, { ascending: sortDirection });
    }

    const { data, count, error } = await query.returns<ContactWithExternalContacts[] | null>();

    if (error) {
        log({
            level: 'error',
            message: `Error getting contacts with count`,
            error,
            extra: {
                company_id,
            },
        });
        return null;
    }

    return {
        data: data ?? [],
        count: count ?? 0,
    };
}

/**
 * This helper function will use a Service client to check if a domain is
 * already used (this will remain true after a company is deleted)
 * @param client A Supabase Service client
 * @param domain The domain to check
 * @returns True if the domain is used, false if it is not, null if an error occurred
 */
export async function checkIfDomainIsUsed(
    client: SupaServiceClient,
    domain: string
): Promise<boolean | null> {
    const { data, error } = await client
        .from('used_domains')
        .select('domain')
        .eq('domain', domain.toLowerCase())
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting company with domain`,
            error,
            extra: {
                domain,
            },
        });
        return null;
    }

    return data !== null;
}

/**
 * This function will get a subscription by its ID
 * @param client The client to use. If a service one is provided the results will be returned regardless
 * if the user has access to the subscription
 * @param subscription_id The id of the subscription
 * @returns A subscription or null if nothing or error
 */
export const getSubscriptionById = async (
    client: SupaAuthedClient | SupaServiceClient,
    subscription_id: string
): Promise<Subscription | null> => {
    const { data, error } = await client
        .from('subscriptions')
        .select('*')
        .eq('subscription_id', subscription_id)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting subscription by id`,
            error,
            extra: {
                subscription_id,
            },
        });
        return null;
    }

    if (!data) {
        return null;
    }

    return data;
};

/**
 * This function will attempt to get a company by its Stripe customer ID
 * @param client The client to use. If a service one is provided the results will be returned regardless
 * if the user has access to the company
 * @param customer_id The Stripe customer ID
 * @returns A company object or null if nothing is found or an error occurs
 */
export const getCompanyByCustomerId = async (
    client: SupaAuthedClient | SupaServiceClient,
    customer_id: string
): Promise<Company | null> => {
    const { data, error } = await client
        .from('companies')
        .select('*')
        .eq('stripe_customer_id', customer_id)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting company by customer id`,
            error,
            extra: {
                customer_id,
            },
        });
        return null;
    }

    return data;
};

/**
 * This function will get the size of a given company
 * @param client The client to use. If a service one is provided the results will be returned regardless
 * if the user has access to the company
 * @param company_id The company id to get the size of
 * @returns The size of the company or null if an error occurred
 */
export async function getCompanySize(
    client: AnySupabaseClient,
    company_id: string
): Promise<number | null> {
    const { count, error } = await client
        .from('company_member_relationships')
        .select('*', { count: 'exact', head: true })
        .eq('company_id', company_id);

    if (error || count === null) {
        log({
            level: 'error',
            message: `Error getting company size`,
            error,
            company_id,
        });
        return null;
    }

    return count;
}

export async function getOrgMembers(
    client: SupaAuthedClient,
    company_id: string
): Promise<OrgMember[]> {
    const { data: members, error } = await client
        .from('company_member_relationships')
        .select('role, profiles(id, full_name, profile_picture, email)')
        .eq('company_id', company_id);

    if (error) {
        log({
            level: 'error',
            message: `Error getting company members`,
            error,
            company_id,
        });
        return [];
    }

    const finalMembers: OrgMember[] = [];

    members?.forEach((member) => {
        if (member.profiles) {
            finalMembers.push({
                uid: member.profiles.id,
                full_name: member.profiles.full_name ?? '',
                profile_picture: member.profiles.profile_picture ?? '',
                role: member.role as UserRoles,
                email: member.profiles.email,
            });
        }
    });

    return finalMembers;
}

/**
 * This function will get a single org member
 * @param client The client to use. If a service one is provided the results will be returned regardless
 * @param company_id The company id to get org member for
 * @param uid The uid of the user to get
 * @returns The org member or null if an error occurred
 */
export async function getOrgMember(
    client: SupaAuthedClient | SupaServiceClient,
    company_id: string,
    uid: string
): Promise<OrgMember | null> {
    const { data: member, error } = await client
        .from('company_member_relationships')
        .select('role, profiles(id, full_name, profile_picture, email)')
        .eq('company_id', company_id)
        .eq('user_id', uid)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting company member`,
            error,
            company_id,
        });
        return null;
    }

    if (!member || !member.profiles) {
        return null;
    }

    return {
        full_name: member.profiles.full_name ?? '',
        profile_picture: member.profiles?.profile_picture ?? '',
        role: member.role as UserRoles,
        uid: member.profiles.id,
        email: member.profiles.email,
    };
}

/**
 * Retrieves the number of finds for a given company ID.
 * @param client - The Supabase client or service client.
 * @param company_id - The ID of the company to retrieve the find count for.
 * @returns A Promise that resolves with the number of finds for the given company ID, or null if an error occurred.
 */
export async function getCompanyFindCount(
    client: SupaAuthedClient | SupaServiceClient,
    company_id: string
): Promise<number | null> {
    const { count, error } = await client
        .from('finds')
        .select('*', { count: 'exact', head: true })
        .eq('company_id', company_id);

    if (error || count === null) {
        if (error && error.message === 'TypeError: Failed to fetch') {
            return null;
        }

        log({
            level: 'error',
            message: `Error getting company find count`,
            error,
            company_id,
        });
        return null;
    }

    return count;
}

/**
 * This function will return a Find by its ID. RLS protects lookups outside of the company.
 * @param client The supabase client to use. If you use a SupaServiceClient, the find will be returned regardless of
 * of if it belongs to the company.
 * @param find_id The ID of the find to get.
 * @returns The find or null if it could not be found.
 */
export async function getFind(client: AnySupabaseClient, find_id: string): Promise<Find | null> {
    const { data, error } = await client
        .from('finds')
        .select('*')
        .eq('id', find_id)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting find`,
            error,
            extra: {
                find_id,
            },
        });
        return null;
    }

    return data;
}

/**
 * This function will return a Find Action by its ID. RLS protects lookups outside of the company.
 * @param client The supabase client to use. If you use a SupaServiceClient, the find will be returned regardless of
 * of if it belongs to the company.
 * @param find_action_id The ID of the find action to get.
 * @returns The find action or null if it could not be found.
 */
export async function getFindAction(
    client: SupaAuthedClient | SupaServiceClient,
    find_action_id: string
): Promise<FindAction | null> {
    const { data, error } = await client
        .from('find_actions')
        .select('*')
        .eq('id', find_action_id)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting find action`,
            error,
            extra: {
                find_action_id,
            },
        });
        return null;
    }

    return data;
}

/**
 * This function will return a all of a Find's actions. RLS protects lookups outside of the company.
 * @param client The supabase client to use. If you use a SupaServiceClient, the find will be returned regardless of
 * of if it belongs to the company.
 * @param find_id The ID of the find to get the actions for
 * @param limit The number of actions to return. Defaults to none.
 * @returns An array of find actions or null if it could not be found. Sorted oldest first.
 */
export async function getFindActions(
    client: SupaAuthedClient | SupaServiceClient,
    find_id: string,
    limit?: number
): Promise<FindAction[] | null> {
    let query = client
        .from('find_actions')
        .select('*')
        .eq('find_id', find_id)
        // oldest first
        .order('created_at', { ascending: true });

    if (limit) {
        query = query.limit(limit);
    }

    const { data, error } = await query;

    if (error) {
        log({
            level: 'error',
            message: `Error getting actions for find`,
            error,
            extra: {
                find_id,
            },
        });
        return null;
    }

    return data;
}

export async function getUnreadFindCount(
    client: SupaAuthedClient | SupaServiceClient,
    company_id: string
): Promise<number | null> {
    const { count, error } = await client
        .from('finds')
        .select('*', { count: 'exact', head: true })
        .eq('company_id', company_id)
        .eq('read_status', 'unread');

    if (error || count === null) {
        log({
            level: 'error',
            message: `Error getting unread find count`,
            error,
            company_id,
        });
        return null;
    }

    return count;
}

export async function getPlatformAlert(
    client: SupaAuthedClient | SupaServiceClient
): Promise<PlatformAlert | null> {
    const { data, error } = await client
        .from('platform_alerts')
        .select('*')
        .order('id', { ascending: false })
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting platform alerts`,
            error,
        });
        return null;
    }

    return data;
}

// export async function getIntegrationProfile(
//     client: SupaClient | SupaServiceClient,
//     service: Service,
//     company_id: string
// ): Promise<string> {
//     let profilePicture = 'https://app.pitchghost.com/images/logo/v2/icon.png';

//     switch (service) {
//         case 'Twitter': {
//             const twitterIntegrationDetails = await getTwitterIntegrationDetails(
//                 client,
//                 company_id
//             );
//             if (twitterIntegrationDetails) {
//                 const twitterAccount = await getLinkedTwitterAccount(company_id);
//                 if (twitterAccount.success) {
//                     profilePicture = twitterAccount.data.user.profile_image_url ?? profilePicture;
//                 }
//             }
//         }
//     }

//     return profilePicture;
// }

/**
 * This generic utility function will return the count of records in a table
 * grouped by days including zeros for days with no records between a given date range.
 * @param client The Supabase client to use. Use a service to bypass RLS
 * @param match_column The name of the column to filter on (e.g. company_id)
 * @param match_value The value of the column to filter on the actual value (e.g. the company_id)
 * @param date_column_name The name of the date column to group by
 * @param table The table to query
 * @param start The start date
 * @param end The end date
 * @returns An array of CountByDay objects
 */
export async function getColumnCountByDate<
    TTable extends keyof Database['public']['Tables'],
    TDateColumn extends keyof Database['public']['Tables'][TTable]['Row'],
    TMatchColumn extends keyof Database['public']['Tables'][TTable]['Row'],
    TMatchColumnValue extends Database['public']['Tables'][TTable]['Row'][TMatchColumn],
>(
    client: SupaAuthedClient | SupaServiceClient,
    match_column: TMatchColumn,
    match_value: TMatchColumnValue,
    date_column_name: TDateColumn,
    table: TTable,
    start: Date,
    end: Date
): Promise<CountByDay[]> {
    const { data, error } = await client.rpc('count_records_by_day', {
        table_name: table,
        date_column_name: date_column_name as string,
        match_column: match_column as string,
        match_value: match_value as string,
        end_date: end.toISOString(),
        start_date: start.toISOString(),
    });

    if (error) {
        log({
            message: 'Error using count_records_by_day rpc',
            level: 'error',
            error,
        });
        return [];
    }

    return data;
}

/**
 * This function get the retry record for a find action
 * @param client The Supabase client to use
 * @param findActionId The ID of the find action
 * @returns The retry record or null if not found (which is usually the case)
 */
export async function getFindActionRetryRecord(
    client: SupaAuthedClient | SupaServiceClient,
    findActionId: string
): Promise<RateLimitedFindAction | null> {
    const { data, error } = await client
        .from('retries')
        .select('*')
        .eq('find_action_id', findActionId)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting rate limited find action data`,
            error,
            extra: {
                findActionId,
            },
        });
        return null;
    }

    return data;
}

/**
 * This function will return a random sample of previously taken find actions for a ghost.
 * If an amount of 10 is passed it will return 10 random find actions for the ghost
 * from the most recent 20.
 * @param client The Supabase client to use
 * @param ghostId The ID of the ghost
 * @param amount The amount of find actions to return
 * @returns An array of find actions or null if an error occurred
 */
export async function getRandomPreviousFindActionsForGhost(
    client: AnySupabaseClient,
    ghostId: string,
    amount: number
): Promise<FindAction[] | null> {
    const { data, error } = await client
        .from('find_actions')
        .select('*')
        .eq('ghost_id', ghostId)
        .in('status', ['taken', 'rate_limited'])
        .order('action_date', { ascending: false })
        .limit(amount * 2);

    if (error) {
        log({
            level: 'error',
            message: `Error getting previous find actions for ghost`,
            error,
            extra: {
                ghostId,
            },
        });
        return [];
    }

    // Return a random sample of the data (equal to 'amount')
    return shuffleArray(data).slice(0, amount);
}

/**
 * This function will return all known external contacts for a given contact
 * @param client The Supabase client to use
 * @param contactId
 * @returns
 */
export async function getContactExternalContacts(
    client: AnySupabaseClient,
    contactId: string
): Promise<ExternalContactRecord[]> {
    const { data, error } = await client
        .from('external_contacts')
        .select('*')
        .eq('contact_id', contactId);

    if (error) {
        log({
            level: 'error',
            message: `Error getting external contacts for contact`,
            error,
            extra: {
                contactId,
            },
        });
        return [];
    }

    return data;
}

/**
 * This function will return a full contact with all of its external contacts
 * @param client The Supabase client to use
 * @param contactId The ID of the contact
 * @returns A full contact or null if not found/error
 */
export async function getFullContact(
    client: AnySupabaseClient,
    contactId: string
): Promise<ContactWithExternalContacts | null> {
    const { data, error } = await client
        .from('contacts')
        .select('*, external_contacts(*)')
        .eq('id', contactId)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error getting full contact`,
            error,
            extra: {
                contactId,
            },
        });
        return null;
    }

    if (!data) {
        return null;
    }

    return data;
}

/**
 * A special
 * @param input
 * @param init
 * @returns
 */
export const supaFetch = async (input: URL | RequestInfo, init?: RequestInit) => {
    init = init || {};

    // init.headers = {
    //     apikey: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    //     ...init.headers,
    // };
    // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImV4cCI6MTk4MzgxMjk5Nn0.EGIM96RAZx35lJzdJsyH-qQwv8Hdp7fsn3W0YpN81IU
    // console.log('headers', init.headers[]);
    // extract the headers apiKey
    const headers = Object.fromEntries(new Headers(init.headers).entries());

    if (!headers.apikey) {
        headers.apikey = getEnvironmentVariable(process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY);
    }

    const res = await ky(input, {
        ...init,
        headers,
        hooks: {
            afterResponse: [
                async (_input, _options, response) => {
                    const clonedBody = response.clone();
                    if (!clonedBody.ok) {
                        log({
                            message: 'Error performing Supabase request',
                            level: 'error',
                            tags: {
                                supabase: true,
                            },
                            extra: {
                                status: clonedBody.status,
                                statusText: clonedBody.statusText,
                                url: clonedBody.url,
                                text: await clonedBody.text(),
                            },
                        });
                    }

                    return response;
                },
            ],
        },
    });
    return res;
};

/**
 * This function will return the invites for a company that have not been fully accepted.
 * An invite is full accepted when the user has created an account and joined the company.
 * @param client A Supabase client that has been authenticated.
 * @param company_id The Supabase company ID to check for
 * @returns The pending invites for the company or null if an error occurred
 */
export async function getPendingInvites(
    client: SupaAuthedClient,
    company_id: string
): Promise<Invite[] | null> {
    const { data, error } = await client.from('invites').select('*').eq('company_id', company_id);

    if (error) {
        log({
            level: 'error',
            message: `Error getting pending invites`,
            error,
            company_id,
        });
        return null;
    }

    return data;
}

export async function getCompanyGhostNames(
    client: AnySupabaseClient,
    company_id: string
): Promise<string[]> {
    const { data, error } = await client
        .from('ghosts')
        .select('nickname')
        .eq('company_id', company_id);

    if (error) {
        log({
            level: 'error',
            message: `Error getting company ghost names`,
            error,
            company_id,
        });
        return [];
    }

    return data.map((ghost) => ghost.nickname);
}

/**
 * This function will check if any given company actively has a ghost in the running status
 * @param client The supabase client to use
 * @param company_id The company ID to check
 * @returns A boolean indicating if the company has an actively running ghost
 */
export async function companyHasActivelyRunningGhost(
    client: AnySupabaseClient,
    company_id: string
): Promise<boolean> {
    const { data, error } = await client
        .from('runs')
        .select('id')
        .eq('company_id', company_id)
        .eq('status', RunStatus.running)
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error checking for actively running ghost`,
            error,
            company_id,
        });
        return false;
    }

    return data?.id ? true : false;
}

/**
 * This function will check if any given Ghost is already running or queued
 * @param client The supabase client to use
 * @param company_id The company ID to check
 * @returns A boolean indicating if the Ghost is already running or queued
 */
export async function isGhostAlreadyRunningOrQueued(
    client: AnySupabaseClient,
    ghost_id: string
): Promise<boolean> {
    const { data, error } = await client
        .from('runs')
        .select('id')
        .eq('ghost_id', ghost_id)
        .in('status', [RunStatus.running, RunStatus.queued])
        .limit(1)
        .maybeSingle();

    if (error) {
        log({
            level: 'error',
            message: `Error checking for actively running ghost`,
            error,
            extra: {
                ghost_id,
            },
        });
        return false;
    }

    return data?.id ? true : false;
}
